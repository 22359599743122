<template>
    <div>
      <form-propiedad :propiedadId="$route.params.id" />
    </div>
  </template>
  
  <script>
  import formPropiedad from "@/components/propiedades/formPropiedad.vue";
  export default {
    components: {
      formPropiedad,
    },
  };
  </script>
  
  <style></style>
  